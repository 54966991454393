<template>
    <div>
        <div class="banner" v-if="ready && !bannerClosed">
            <div :class="['bannerCont', { hide: openPreferences }]" ref="bannerCont">
                <div class="bannerInfo">
                    <div class="paragraph">
                        <h3>{{ t('cookie.title') }}</h3>
                        <p>{{ t('cookie.paragraph') }} <NuxtLinkLocale to="/cookie-policy" :external="true" class="alink">{{ t('cookie.policy') }}</NuxtLinkLocale>. {{ t('cookie.paragraph2') }}</p>
                        <p>{{ t('cookie.view') }}, <NuxtLinkLocale to="/privacy" :external="true">{{ t('cookie.view2') }}</NuxtLinkLocale>.</p>
                    </div>
                    <div class="buttons">
                        <NButton size="medium" secondary round @click="nopeBannerClicked">{{ t('cookie.button.reject') }}</NButton>
                        <NButton size="medium" secondary round @click="preferencesClicked">
                            <template #icon>
                                <NIcon :size="18" :component="ChecklistRound" color="#000000" :aria-label="t('cookie.button.preferences')" />
                            </template>
                            {{ t('cookie.button.preferences') }}
                        </NButton>
                        <NButton size="medium" type="primary" round @click="okBannerClicked">{{ t('cookie.button.accept') }}</NButton>
                    </div>
                </div>
            </div>
            <div :class="['preferencesCont', { show: openPreferences }]" ref="preferencesCont">
                <div class="preferencesInfo">
                    <div class="paragraph">
                        <h3>{{ t('cookie.preferences.title') }}</h3>
                        <p>{{ t('cookie.preferences.paragraph') }}</p>
                    </div>
                </div>
                <div class="preferencesButton">
                    <NButton size="small" secondary round @click="selectNone">
                        <template #icon>
                            <NIcon :size="18" :component="CheckRound" color="#000000" :aria-label="t('cookie.button.rejectall')" />
                        </template>
                        {{ t('cookie.button.rejectall') }}
                    </NButton>
                    <NButton size="small" secondary round @click="selectAll">
                        <template #icon>
                            <NIcon :size="18" :component="CloseRound" color="#000000" :aria-label="t('cookie.button.accept')" />
                        </template>{{ t('cookie.button.accept') }}
                    </NButton>
                </div>
                <div class="scrollablePreferences noselect">
                    <div class="preferences">
                        <div class="preference smallParagraph">
                            <!--<div class="preferenceIntro">
                                <strong>Le tue preferenze relative al consenso per le tecnologie di tracciamento</strong>
                                <p>Le opzioni disponibili in questa sezione ti permettono di personalizzare le preferenze relative al consenso per qualsiasi tecnologia di tracciamento utilizzata per le finalità descritte di seguito. Per ottenere ulteriori informazioni in merito all'utilità e al funzionamento di tali strumenti di tracciamento, fai riferimento alla cookie policy. Tieni presente che il rifiuto del consenso per una finalità particolare può rendere le relative funzioni non disponibili.</p>
                            </div>-->
                            <div class="preferenceTitle">
                                <strong>{{ t('cookie.preferences.necessari') }}</strong>
                                <NSwitch v-model:value="cookiesNecessari" size="medium" disabled />
                            </div>
                            <p>{{ t('cookie.preferences.necessaridesc') }}</p>
                        </div>
                        <div class="preference smallParagraph">
                            <div class="preferenceTitle">
                                <strong>{{ t('cookie.preferences.misurazione') }}</strong>
                                <NSwitch v-model:value="cookiesMisurazione" size="medium" />
                            </div>
                            <p>{{ t('cookie.preferences.misurazionedesc') }}</p>
                        </div>
                        <div class="preference smallParagraph">
                            <div class="preferenceTitle">
                                <strong>{{ t('cookie.preferences.marketing') }}</strong>
                                <NSwitch v-model:value="cookiesMarketing" size="medium" />
                            </div>
                            <p>{{ t('cookie.preferences.marketingdesc') }}</p>
                        </div>
                    </div>
                </div>
                <div class="buttons">
                    <NButton size="medium" secondary circle @click="preferencesClosed">
                        <template #icon>
                            <NIcon :size="18" :component="ArrowBackRound" color="#000000" :aria-label="''" />
                        </template>
                    </NButton>
                    <NButton size="medium" type="primary" round @click="savePreferences">{{ t('cookie.button.save') }}</NButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { NButton, NIcon, NSwitch } from 'naive-ui'
import { ArrowBackRound, ChecklistRound, CheckRound, CloseRound } from '@vicons/material'

const preferences = usePreferencesStore()

const { bannerClosed, misurazione, marketing, gdpr } = storeToRefs(preferences)
const { t } = useI18n({ useScope: 'local' })

const ready = ref(false)
const openPreferences = ref(false)
const bannerCont = ref<HTMLElement>()
const preferencesCont = ref<HTMLElement>()

const cookiesNecessari = ref(true)
const cookiesMisurazione = ref(misurazione.value || gdpr.value)
const cookiesMarketing = ref(marketing.value || gdpr.value)

const { height: bannerContHeight } = useElementSize(bannerCont)
const { height: preferencesContHeight } = useElementSize(preferencesCont)

const bannerHeight = computed(() => {
    if (bannerContHeight.value && openPreferences.value === false) return `${bannerContHeight.value}px`
    else if (preferencesContHeight.value && openPreferences.value === true) return `${preferencesContHeight.value}px`
    else return 'auto'
})

const nopeBannerClicked = () => {
    bannerClosed.value = true
    cookiesMisurazione.value = false
    cookiesMarketing.value = false
    misurazione.value = false
    marketing.value = false
}
const preferencesClicked = () => {
    openPreferences.value = true
}
const preferencesClosed = () => {
    openPreferences.value = false
}
const okBannerClicked = () => {
    bannerClosed.value = true
    cookiesMisurazione.value = true
    cookiesMarketing.value = true
    misurazione.value = true
    marketing.value = true
}

const selectAll = () => {
    cookiesMisurazione.value = true
    cookiesMarketing.value = true
}
const selectNone = () => {
    cookiesMisurazione.value = false
    cookiesMarketing.value = false
}

const savePreferences = () => {
    bannerClosed.value = true
    misurazione.value = cookiesMisurazione.value
    marketing.value = cookiesMarketing.value
    openPreferences.value = false
}

onMounted(() => {
    ready.value = true
})
</script>

<style lang="scss" scoped>
.banner {
    position: fixed;
    right: var(--margin-default);
    bottom: var(--margin-default);
    margin-left: var(--margin-default);

    height: v-bind(bannerHeight);
    width: calc(100% - (var(--margin-default) * 2));
    max-width: 600px;
    overflow: hidden;

    transition: height 250ms 0ms ease-in-out;

    //border: 1px solid var(--color-background-gray);

    border-radius: var(--size-card-radius);
    background-color: var(--color-background-footer);
    color: white;

    z-index: 999;
    padding: var(--margin-default) 0;

    .bannerCont {
        position: absolute;
        bottom: var(--margin-default);
        left: 0;
        right: 0;

        transform: translate3d(0, 0, 0);
        transition: opacity 250ms 250ms ease-in-out;

        .bannerInfo {
            padding: 0 var(--margin-default);

            display: flex;
            flex-direction: column;
            gap: 40px;

            p {
                max-width: 530px;

                a {
                    color: white;
                }
            }
        }

        &.hide {
            opacity: 0;
            pointer-events: none;
            transition: opacity 250ms 0ms ease-in-out;
        }
    }

    .preferencesCont {
        position: absolute;

        transform: translate3d(0, 0, 0);
        opacity: 0;
        pointer-events: none;
        transition: opacity 250ms 0ms ease-in-out;

        .preferencesInfo {
            padding: 0 var(--margin-default);

            h3 {
                text-wrap: balance;
            }
        }

        .preferencesButton {
            padding: var(--margin-default);
            display: flex;
            gap: 12px
        }

        .scrollablePreferences {
            height: 250px;
            margin: 0 var(--margin-default);
            background-color: var(--color-background-gray);
            border-radius: var(--size-card-radius-inner);

            overflow: scroll;

            .preferences {
                background-color: var(--color-background-footer);

                display: flex;
                flex-direction: column;
                gap: 1px;

                .preference {
                    padding: var(--margin-default) 18px;
                    background-color: var(--color-background-gray);

                    line-height: 1rem;
                    text-wrap: balance;

                    display: flex;
                    flex-direction: column;
                    gap: 6px;

                    .preferenceIntro {
                        margin-bottom: 30px;
                    }

                    .preferenceTitle {
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                    }
                }
            }
        }

        .buttons {
            padding: var(--margin-default) var(--margin-default) 0 var(--margin-default);
        }


        &.show {
            opacity: 1;
            pointer-events: auto;
            transition: opacity 250ms 250ms ease-in-out;
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 12px;
    }
}

@media screen and (max-width: 600px) {
    .banner {
        padding: 24px 0;

        .bannerCont {
            .bannerInfo {
                flex-direction: column;
                gap: 24px;

                h3 {
                    margin-bottom: 6px;
                }

                p {
                    font-size: var(--font-size-small);
                    line-height: 1rem;
                }
            }
        }

        .preferencesCont {
            .preferencesInfo {
                h3 {
                    margin-bottom: 6px;
                }

                p {
                    font-size: var(--font-size-small);
                    line-height: 1rem;
                }
            }

            .scrollablePreferences {
                height: 185px;
            }
        }
    }
}
</style>

<i18n lang="json">{
    "en": {
        "cookie": {
            "title": "This website uses cookies",
            "paragraph": "We and some partners selected use cookies or similar technologies as specified in the",
            "policy": "Cookie Policy",
            "paragraph2": "You can consent to the use of such technologies using the button \"Accept\".",
            "view": "To view the Privacy Policy",
            "view2": "click here",
            "button": {
                "accept": "Accept all",
                "preferences": "Preferences",
                "reject": "Reject",
                "rejectall": "Reject all",
                "save": "Save and continue"
            },
            "preferences": {
                "title": "Your Privacy Choices",
                "paragraph": "In this panel you can express some preferences related to the processing of your personal information. To deny your consent to the specific processing activities described below, switch the toggles to off or use the “Reject all” button and confirm you want to save your choices.",
                "necessari": "Necessary",
                "necessaridesc": "These trackers are used for activities that are strictly necessary to operate or deliver the service you requested from us and, therefore, do not require you to consent.",
                "misurazione": "Measurement",
                "misurazionedesc": "These trackers help us to measure traffic and analyze your behavior to improve our service.",
                "marketing": "Marketing",
                "marketingdesc": "Questi strumenti di tracciamento ci permettono di fornirti contenuti marketing o annunci personalizzati e di misurarne la performance.These trackers help us to deliver personalized ads or marketing content to you, and to measure their performance."
            }
        }
    },
    "it": {
        "cookie": {
            "title": "Questo sito web utilizza i cookie",
            "paragraph": "Noi e alcuni partner selezionati utilizziamo cookie o tecnologie simili come specificato nella",
            "policy": "Cookie Policy",
            "paragraph2": "Puoi consentire all'utilizzo di tali tecnologie utilizzando il pulsante \"Accetta\".",
            "view": "Per visualizzare la Privacy Policy",
            "view2": "cliccare qui",
            "button": {
                "accept": "Accetta tutti",
                "preferences": "Preferenze",
                "reject": "Rifiuta",
                "rejectall": "Rifiuta tutto",
                "save": "Salva e continua"
            },
            "preferences": {
                "title": "Le tue preferenze relative alla privacy",
                "paragraph": "Questo pannello ti permette di esprimere alcune preferenze relative al trattamento delle tue informazioni personali. Per rifiutare il tuo consenso alle attività di trattamento descritte di seguito, disattiva i singoli comandi o utilizza il pulsante “Rifiuta tutto” e conferma di voler salvare le scelte effettuate.",
                "necessari": "Necessari",
                "necessaridesc": "Questi strumenti di tracciamento sono strettamente necessari per garantire il funzionamento e la fornitura del servizio che ci hai richiesto e, pertanto, non richiedono il tuo consenso.",
                "misurazione": "Misurazione",
                "misurazionedesc": "Questi strumenti di tracciamento ci permettono di misurare il traffico e analizzare il tuo comportamento per migliorare il nostro servizio.",
                "marketing": "Marketing",
                "marketingdesc": "Questi strumenti di tracciamento ci permettono di fornirti contenuti marketing o annunci personalizzati e di misurarne la performance."
            }
        }
    },
    "fr": {
        "cookie": {
            "title": "Ce site web utilise des cookies",
            "paragraph": "Nous et certains partenaires sélectionnés utilisent des cookies ou des technologies similaires telles que spécifiées dans la",
            "policy": "Politique de gestion des cookies",
            "paragraph2": "Vous pouvez consentir à l'utilisation de telles technologies en utilisant le bouton \"Accepter\".",
            "view": "Pour afficher la politique de confidentialité",
            "view2": "cliquer ici",
            "button": {
                "accept": "Accepter tout",
                "preferences": "Préférences",
                "reject": "Rejeter",
                "rejectall": "Tout refuser",
                "save": "Enregistrer et continuer"
            },
            "preferences": {
                "title": "Vos choix en matière de confidentialité",
                "paragraph": "Dans ce panneau, vous pouvez exprimer certaines préférences relatives au traitement de vos informations personnelles. Pour refuser votre consentement aux activités de traitement spécifiques décrites ci-dessous, basculez les touches pour les désactiver ou utilisez le bouton « Tout refuser » et confirmez que vous voulez enregistrer vos choix.",
                "necessari": "Nécessaires",
                "necessaridesc": "Ces traqueurs sont utilisés pour des activités strictement nécessaires au fonctionnement ou à la fourniture des services que vous nous avez demandés et, par conséquent, n’exigent pas votre consentement.",
                "misurazione": "Mesure",
                "misurazionedesc": "Ces traqueurs nous permettent de mesurer le trafic et d’analyser votre comportement afin d’améliorer notre service.",
                "marketing": "Marketing",
                "marketingdesc": "Ces traqueurs nous permettent de vous diffuser des publicités ou du contenu marketing personalisés, et de mesurer leur efficacité."
            }
        }
    }
}</i18n>